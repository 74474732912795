/* eslint-disable max-lines */
import { useEffect, useState } from 'react';
import Api, { endpoints } from '@Helpers/api';
import moment from 'moment';

export default function hook() {
  const [year, setYear] = useState(null);
  const [type, setType] = useState('Financial');
  const [chartData, setChartData] = useState({});
  const [tableData, setTableData] = useState({});

  useEffect(() => {
    const scheduleData = [
      { x: 'Jan-21', y: 0 },
      { x: 'Feb-21', y: 0 },
      { x: 'Mar-21', y: 0 },
      { x: 'Apr-21', y: 0 },
      { x: 'May-21', y: 0 },
      { x: 'June-21', y: 5708227.85 },
      { x: 'July-21', y: 6814072.93 },
      { x: 'Aug-21', y: 7558009.27 },
      { x: 'Sept-21', y: 9101014.60 },
      { x: 'Oct-21', y: 11323205.31 },
      { x: 'Nov-21', y: 12182250.53 },
      { x: 'Dec-21', y: 17932420.81 },
      { x: 'Jan-22', y: 19932832.91 },
      { x: 'Feb-22', y: 22006044.41 },
      { x: 'Mar-22', y: 24255206.00 },
      { x: 'Apr-22', y: 26452410.39 },
      { x: 'May-22', y: 29785743.72 },
      { x: 'June-22', y: 35750648.76 },
      { x: 'July-22', y: 44026117.79 },
      { x: 'Aug-22', y: 52065832.61 },
      { x: 'Sept-22', y: 53065832.62 },
      { x: 'Oct-22', y: 58892092.60 },
      { x: 'Nov-22', y: 61532548.00 },
      { x: 'Dec-22', y: 62867913.75 },
      { x: 'Jan-23', y: 65031363.75 },
      { x: 'Feb-23', y: 70031363.75 },
      { x: 'Mar-23', y: 74539204.48 },
      { x: 'Apr-23', y: 82639204.48 },
      { x: 'May-23', y: 97355982.55 },
      { x: 'June-23', y: 97812213.90 },
      { x: 'July-23', y: 97355982.55 },
      { x: 'Aug-23', y: 111419553.74 },
      { x: 'Sept-23', y: 120719553.74 },
      { x: 'Oct-23', y: 134917300.49 },
      { x: 'Nov-23', y: 147833487.90 },
      { x: 'Dec-23', y: 148333542.41 },
      { x: 'Jan-24', y: 154851625.79 },
      { x: 'Feb-24', y: 164351625.79 },
      { x: 'Mar-24', y: 176088012.63 },
      { x: 'Apr-24', y: 184082180.19 },
      { x: 'May-24', y: 193772228.41 },
    ];
    const actualData = [
      { x: 'Jan-21', y: 0 },
      { x: 'Feb-21', y: 0 },
      { x: 'Mar-21', y: 0 },
      { x: 'Apr-21', y: 0 },
      { x: 'May-21', y: 0 },
      { x: 'June-21', y: 5708227.85 },
      { x: 'July-21', y: 6814072.93 },
      { x: 'Aug-21', y: 7558009.27 },
      { x: 'Sept-21', y: 9101014.60 },
      { x: 'Oct-21', y: 11323205.31 },
      { x: 'Nov-21', y: 12182250.53 },
      { x: 'Dec-21', y: 17932420.81 },
      { x: 'Jan-22', y: 19932832.91 },
      { x: 'Feb-22', y: 22006044.41 },
      { x: 'Mar-22', y: 24255206.00 },
      { x: 'Apr-22', y: 26452410.39 },
      { x: 'May-22', y: 29785743.72 },
      { x: 'June-22', y: 35750648.76 },
      { x: 'July-22', y: 44026117.79 },
      { x: 'Aug-22', y: 52065832.61 },
      { x: 'Sept-22', y: 53065832.62 },
      { x: 'Oct-22', y: 58892092.60 },
      { x: 'Nov-22', y: 61532548.00 },
      { x: 'Dec-22', y: 62867913.75 },
      { x: 'Jan-23', y: 65031363.75 },
      { x: 'Feb-23', y: 70031363.75 },
      { x: 'Mar-23', y: 74539204.48 },
      { x: 'Apr-23', y: 82639204.48 },
      { x: 'May-23', y: 97355982.55 },
      { x: 'June-23', y: 97812213.90 },
      { x: 'July-23', y: 97355982.55 },
      { x: 'Aug-23', y: 111419553.74 },
      { x: 'Sept-23', y: 120719553.74 },
      { x: 'Oct-23', y: 134917300.49 },
      { x: 'Nov-23', y: 147833487.90 },
      { x: 'Dec-23', y: 125612443.75 },
      { x: 'Jan-24', y: 133649235.82 },
      { x: 'Feb-24', y: 143649235.82 },
      { x: 'Mar-24', y: 152886929.91 },
      { x: 'Apr-24', y: 171733226.08 },
      { x: 'May-24', y: 183451425.47 },
      { x: 'June-24', y: 204695330.97 },
      { x: 'July-24', y: 214623197.62 },
      { x: 'Aug-24', y: 221901779.36 },
      { x: 'Sept-24', y: 235733650.44 },
      { x: 'Oct-24', y: 258562159.65 },
      { x: 'Nov-24', y: 277804966.10 },
      { x: 'Dec-24', y: 315994493.79 },
      { x: 'Jan-25', y: 329349218.43 },
      { x: 'Feb-25', y: 343615449.58 },
      { x: 'Mar-25', y: 362964686.41 },
      { x: 'Apr-25', y: 377500904.87 },
      { x: 'May-25', y: 403254239.27 },
      { x: 'June-25', y: 410275123.02 },
      { x: 'July-25', y: 410300000.00 },
    ];
    setTableData([
      {
        monthYear: '1/2024',
        scheduleCurve: 154851625.79,
        actualCurve: 133649235.82,
        financialVariance: -21202389.97,
      },
      {
        monthYear: '2/2024',
        scheduleCurve: 164351625.79,
        actualCurve: 143649235.82,
        financialVariance: -20702389.97,
      },
      {
        monthYear: '3/2024',
        scheduleCurve: 176088012.63,
        actualCurve: 152886929.91,
        financialVariance: -23201082.72,
      },
      {
        monthYear: '4/2024',
        scheduleCurve: 184082180.19,
        actualCurve: 171733226.08,
        financialVariance: -12348954.11,
      },
      {
        monthYear: '5/2024',
        scheduleCurve: 193772228.41,
        actualCurve: 183451425.47,
        financialVariance: -10320802.94,
      }]);

    const scheduleDataP = [
      { x: 'Jan-21', y: 0.13 },
      { x: 'Feb-21', y: 0.88 },
      { x: 'Mar-21', y: 1.43 },
      { x: 'Apr-21', y: 3.48 },
      { x: 'May-21', y: 3.78 },
      { x: 'June-21', y: 3.78 },
      { x: 'July-21', y: 3.79 },
      { x: 'Aug-21', y: 4.50 },
      { x: 'Sept-21', y: 5.30 },
      { x: 'Oct-21', y: 8.18 },
      { x: 'Nov-21', y: 6.51 },
      { x: 'Dec-21', y: 7.33 },
      { x: 'Jan-22', y: 7.68 },
      { x: 'Feb-22', y: 9.82 },
      { x: 'Mar-22', y: 12.65 },
      { x: 'Apr-22', y: 15.60 },
      { x: 'May-22', y: 16.75 },
      { x: 'June-22', y: 17.58 },
      { x: 'July-22', y: 18.44 },
      { x: 'Aug-22', y: 19.20 },
      { x: 'Sept-22', y: 20.50 },
      { x: 'Oct-22', y: 22.40 },
      { x: 'Nov-22', y: 24.58 },
      { x: 'Dec-22', y: 25.78 },
      { x: 'Jan-23', y: 27.92 },
      { x: 'Feb-23', y: 28.91 },
      { x: 'Mar-23', y: 30.63 },
      { x: 'Apr-23', y: 32.74 },
      { x: 'May-23', y: 34.29 },
      { x: 'June-23', y: 36.24 },
      { x: 'July-23', y: 38.01 },
      { x: 'Aug-23', y: 40.98 },
      { x: 'Sept-23', y: 42.48 },
      { x: 'Oct-23', y: 46.43 },
      { x: 'Nov-23', y: 50.63 },
      { x: 'Dec-23', y: 44.18 },
      { x: 'Jan-24', y: 46.23 },
      { x: 'Feb-24', y: 50.33 },
      { x: 'Mar-24', y: 53.84 },
      { x: 'Apr-24', y: 56.43 },
    ];

    const actualDataP = [
      { x: 'Jan-21', y: 0.13 },
      { x: 'Feb-21', y: 0.88 },
      { x: 'Mar-21', y: 1.43 },
      { x: 'Apr-21', y: 3.48 },
      { x: 'May-21', y: 3.78 },
      { x: 'June-21', y: 3.78 },
      { x: 'July-21', y: 3.79 },
      { x: 'Aug-21', y: 4.50 },
      { x: 'Sept-21', y: 5.30 },
      { x: 'Oct-21', y: 8.18 },
      { x: 'Nov-21', y: 6.51 },
      { x: 'Dec-21', y: 7.33 },
      { x: 'Jan-22', y: 7.68 },
      { x: 'Feb-22', y: 9.82 },
      { x: 'Mar-22', y: 12.65 },
      { x: 'Apr-22', y: 15.60 },
      { x: 'May-22', y: 16.75 },
      { x: 'June-22', y: 17.58 },
      { x: 'July-22', y: 18.44 },
      { x: 'Aug-22', y: 19.20 },
      { x: 'Sept-22', y: 20.50 },
      { x: 'Oct-22', y: 22.40 },
      { x: 'Nov-22', y: 24.58 },
      { x: 'Dec-22', y: 25.78 },
      { x: 'Jan-23', y: 27.92 },
      { x: 'Feb-23', y: 28.91 },
      { x: 'Mar-23', y: 30.63 },
      { x: 'Apr-23', y: 32.74 },
      { x: 'May-23', y: 34.29 },
      { x: 'June-23', y: 36.24 },
      { x: 'July-23', y: 38.01 },
      { x: 'Aug-23', y: 40.98 },
      { x: 'Sept-23', y: 42.48 },
      { x: 'Oct-23', y: 46.43 },
      { x: 'Nov-23', y: 50.63 },
      { x: 'Dec-23', y: 40.15 },
      { x: 'Jan-24', y: 43.28 },
      { x: 'Feb-24', y: 45.90 },
      { x: 'Mar-24', y: 49.64 },
      { x: 'Apr-24', y: 52.23 },
      { x: 'May-24', y: 55.73 },
      { x: 'June-24', y: 58.56 },
      { x: 'July-24', y: 62.30 },
      { x: 'Aug-24', y: 64.56 },
      { x: 'Sept-24', y: 69.49 },
      { x: 'Oct-24', y: 72.87 },
      { x: 'Nov-24', y: 77.78 },
      { x: 'Dec-24', y: 82.63 },
      { x: 'Jan-25', y: 85.33 },
      { x: 'Feb-25', y: 88.19 },
      { x: 'Mar-25', y: 92.48 },
      { x: 'Apr-25', y: 95.63 },
      { x: 'May-25', y: 98.38 },
      { x: 'June-25', y: 99.20 },
      { x: 'July-25', y: 99.68 },
      { x: 'Aug-25', y: 100.00 },
    ];
    if (type == 'Financial') setChartData({ unit: 'RM', scheduleData, actualData });
    if (type == 'Physical') setChartData({ scheduleData: scheduleDataP, actualData: actualDataP });
  }, [type]);

  return {
    year,
    setYear,
    type,
    setType,
    chartData,
    tableData,
  };
}
